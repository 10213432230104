import {useMemo} from 'react';
import {combineReducers, createStore, Store} from 'redux';
import {handleActions} from 'redux-actions';
import {getHandlers} from '../../api';

export default (): Store => {
  const handlersDictionary = getHandlers();
  const entries = Object.entries(handlersDictionary);

  return useMemo<Store>(
    () =>
      createStore(
        combineReducers(
          Object.fromEntries(
            entries.map(([key, {handlers, defaultState}]) => {
              return [key, handleActions(handlers, defaultState)];
            }),
          ),
        ),
      ),
    [entries.length],
  );
};
