import {Fragment} from 'react';
import {State} from '../typing';

export default {
  routing: {
    routes: [],
    middleware: {},
  },
  layout: Fragment,
  providers: [],
  stores: {},
  menus: {},
} as State;
