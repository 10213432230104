import {Button, ButtonProps} from '@material-ui/core';
import React from 'react';
import {useFormikContext} from 'formik';

const SubmitButton = ({children, ...props}: ButtonProps) => {
  const {isSubmitting} = useFormikContext();

  return (
    <Button type="submit" disabled={isSubmitting} variant="contained" color="primary" {...props}>
      {children ?? 'Submit'}
    </Button>
  );
};

export default SubmitButton;
