import React from 'react';
import {Switch, Route} from 'react-router-dom';
import {NamedRouteWithMiddlewareProps} from '../../typing';
import useRoutes from './useRoutes';

const View = () => {
  const routes = useRoutes();

  if (routes.length === 0) {
    return null;
  }

  return (
    <Switch>
      {routes.map(({name, ...route}: NamedRouteWithMiddlewareProps) => {
        return <Route key={name} {...route} />;
      })}
    </Switch>
  );
};

export default View;
