import {Field as FormikField, FieldAttributes} from 'formik';
import {TextField} from 'formik-material-ui';
import {
  DatePicker,
  DateTimePicker,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
  TimePicker,
} from 'formik-material-ui-pickers';
import React from 'react';
import useId from '../../../../common/hooks/useId';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Field = (props: FieldAttributes<any>) => {
  const {name, component} = props;
  const id = useId(...(process.env.NODE_ENV === 'production' ? [] : ['field-', `-${name}`]));

  const defaultProps = {
    component: TextField,
    id,
    ...([DatePicker, KeyboardDatePicker].includes(component) ? {format: 'dd/MM/yyyy'} : {}),
    ...([TimePicker, KeyboardTimePicker].includes(component) ? {format: 'HH:mm'} : {}),
    ...([DateTimePicker, KeyboardDateTimePicker].includes(component) ? {format: 'dd/MM/yyyy HH:mm'} : {}),
  };

  return <FormikField {...defaultProps} {...props} />;
};

export default Field;
