import {getMiddlewareDictionary} from '../../api';
import {NamedRouteWithMiddlewareProps} from '../../typing';

export default ({middleware: middlewareList = [], ...route}: NamedRouteWithMiddlewareProps) => {
  const list = Array.isArray(middlewareList) ? middlewareList : [middlewareList];
  const middlewareDictionary = getMiddlewareDictionary();

  return list.reduce((processedRoute, middlewareReference) => {
    const middleware =
      typeof middlewareReference === 'string' ? middlewareDictionary[middlewareReference] : middlewareReference;

    if (!middleware) {
      throw new Error(`Middleware [${middlewareReference}] in route [${route.name ?? '(unnamed)'}] does not exist.`);
    }

    return middleware(processedRoute);
  }, route);
};
