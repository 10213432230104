import {Theme as MuiTheme} from '@material-ui/core';
import * as overrides from './overrides';
import * as props from './props';
import theme from './theme';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toPropertyDescriptions = (collection: {[k: string]: any | ((theme: MuiTheme) => any)}) =>
  Object.fromEntries(
    Object.entries({...collection}).map(([key, value]) => [
      key,
      {
        value: typeof value === 'function' ? value(theme) : value,
      },
    ]),
  );

Object.defineProperties(theme.overrides, toPropertyDescriptions(overrides));
Object.defineProperties(theme.props, toPropertyDescriptions(props));

export default theme;
