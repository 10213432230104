import {Button, ButtonProps} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import useStyles from './useStyles';

const ActionButton = ({className, ...props}: ButtonProps) => {
  const classes = useStyles();

  return (
    <Button className={clsx(classes.root, className)} {...props}>
      Dismiss
    </Button>
  );
};

export default ActionButton;
