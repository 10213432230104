import {useState} from 'react';

let id = 0;

export default (prefix = '', suffix = '') => {
  const freshId = id + 1;
  const [value] = useState(freshId);

  if (value === freshId) {
    id++;
  }

  return `${prefix}${value}${suffix}`;
};
