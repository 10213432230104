import React from 'react';
import {getLayout} from '../../api';
import Providers from '../Providers';
import Router from '../Router';
import Store from '../Store';
import View from '../View';

const App = () => {
  const Layout = getLayout();

  return (
    <Store>
      <Providers>
        <Router>
          <Layout>
            <View />
          </Layout>
        </Router>
      </Providers>
    </Store>
  );
};

export default App;
