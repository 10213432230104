import React, {StrictMode} from 'react';
import {render} from 'react-dom';
import {App} from './core';
import domReady from './domReady';
import './modules';

const rootElement = document.querySelector('#root');

(async () => {
  await domReady;
  document.documentElement.classList.add('ready');
})();

render(
  <StrictMode>
    <App />
  </StrictMode>,
  rootElement,
);
