import {useMemo} from 'react';
import {getRoutes} from '../../api';
import {NamedRouteProps} from '../../typing';
import byWeight from '../../utils/byWeight';
import toNamedRouteProps from './toNamedRouteProps';

export default (): Array<NamedRouteProps> => {
  const routes = getRoutes();

  return useMemo(() => [...routes].sort(byWeight).map((route) => toNamedRouteProps(route)), [routes.length]);
};
