import {unstable_createMuiStrictModeTheme as createMuiTheme} from '@material-ui/core/styles';
import mixins from './mixins';
import palette, {post as palettePost} from './palette';
import shape from './shape';
import transitions from './transitions';
import typography, {post as typographyPost} from './typography';

const postProcessors = [palettePost, typographyPost];

const theme = createMuiTheme({
  mixins,
  palette,
  shape,
  transitions,
  typography,
});

postProcessors.forEach((postProcessor) => {
  postProcessor(theme);
});

export default theme;
