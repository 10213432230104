import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SecuredPage from '../SecuredPage';

const AboutPage = () => {
  return (
    <>
      <SecuredPage>
        <Typography variant="h2">Flask - React - Zappa template application</Typography>
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant="h3">Made with</Typography>
          </Grid>
          <Grid item>
            <FavoriteIcon style={{color: 'rgb(253,64,129)'}} />
          </Grid>
          <Grid item>
            <Typography variant="h3">by XpertSea Engineering</Typography>
          </Grid>
        </Grid>
      </SecuredPage>
    </>
  );
};

export default AboutPage;
