import {useMemo, useState} from 'react';
import * as yup from 'yup';
import {Yup} from '../../../contexts/YupContext';

export default (): Yup => {
  const [locale, setLocale] = useState<unknown>(null);

  return useMemo(
    () => ({
      ...yup,
      get locale() {
        return locale;
      },
      setLocale: (nextLocale: unknown) => {
        yup.setLocale(nextLocale);
        setLocale(nextLocale);
      },
    }),
    [locale],
  );
};
