import React, {forwardRef} from 'react';
import useBrandName from '../../../../common/hooks/useBrandName';
import logo from './logo.svg';
import Props from './Props';

const Logo = forwardRef<HTMLImageElement, Props>((props, ref) => {
  const brandName = useBrandName();

  return <img ref={ref} src={logo} alt={brandName} {...props} />;
});

export default Logo;
