import {SnackbarKey, SnackbarProvider as NotistackProvider} from 'notistack';
import React, {useRef} from 'react';
import {ProviderProps} from '../../../../../core';
import ActionButton from '../../snackbar/ActionButton';

const SnackbarProvider = ({children}: ProviderProps) => {
  const notistackRef = useRef<NotistackProvider>(null);

  const handleClickFactory = (key: SnackbarKey) => () => {
    notistackRef.current?.closeSnackbar(key);
  };

  return (
    <NotistackProvider
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      autoHideDuration={5000}
      maxSnack={3}
      ref={notistackRef}
      action={(key) => <ActionButton onClick={handleClickFactory(key)} />}>
      {children}
    </NotistackProvider>
  );
};

export default SnackbarProvider;
