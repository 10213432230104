import {Form as BaseForm, FormikFormProps, useFormikContext} from 'formik';
import React, {forwardRef, useEffect} from 'react';
import useYup from '../../../../common/hooks/useYup';

const Form = forwardRef<HTMLFormElement, FormikFormProps>((props, ref) => {
  const Yup = useYup();
  const {errors, touched, setFieldTouched} = useFormikContext();

  useEffect(() => {
    if (Yup.locale) {
      Object.keys(errors).forEach((field) => {
        if (Object.keys(touched).includes(field)) {
          setFieldTouched(field);
        }
      });
    }
  }, [Yup.locale]);

  return <BaseForm ref={ref} {...props} />;
});

export default Form;
