import React, {createContext} from 'react';

export interface Token {
  accessToken: string;
  expiresAt: number;
}

export interface Auth {
  token: Token | null;
  setToken: React.Dispatch<React.SetStateAction<Token | null>>;
}

export default createContext<Auth>(null as never);
