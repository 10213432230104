import axios from 'axios';
import React from 'react';
import {Token} from '../contexts/AuthContext';

const LOGIN_PATH = '/api/login';

export async function login(
  credentials: {username: string; password: string},
  setToken: React.Dispatch<React.SetStateAction<Token | null>>,
) {
  try {
    const {data} = await axios.get(LOGIN_PATH, {
      headers: {username: credentials.username, password: credentials.password},
    });
    if (data.accessToken && data.expiresAt) {
      const token = {accessToken: data.accessToken, expiresAt: data.expiresAt};
      setToken(token);
      localStorage.setItem('currentAuth', JSON.stringify(token));
      return token;
    }

    setToken(null);
    localStorage.removeItem('currentAuth');
  } catch {
    setToken(null);
    localStorage.removeItem('currentAuth');
  }

  return null;
}

export async function logout(setToken: React.Dispatch<React.SetStateAction<Token | null>>) {
  setToken(null);
  localStorage.removeItem('currentAuth');
}
