import React from 'react';
import {ProviderProps} from '../../../../../core';
import YupContext from '../../../contexts/YupContext';
import useMakeYup from './useMakeYup';

const YupProvider = ({children}: ProviderProps) => {
  const Yup = useMakeYup();

  return <YupContext.Provider value={Yup}>{children}</YupContext.Provider>;
};

export default YupProvider;
