import {Container, Paper, Snackbar, Typography} from '@material-ui/core';
import {Formik} from 'formik';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {login} from '../../../common/actions/auth';
import useAuth from '../../../common/hooks/useAuth';
import useYup from '../../../common/hooks/useYup';
import Logo from '../../../ui/components/brand/Logo';
import Field from '../../../ui/components/form/Field';
import Form from '../../../ui/components/form/Form';
import SubmitButton from '../../../ui/components/form/SubmitButton';

const useSchema = () => {
  const Yup = useYup();

  return Yup.object().shape({
    username: Yup.string().email('Enter a valid email').required('Username must be specified'),
    password: Yup.string().required('Password must be specified'),
  });
};

const LoginPage = () => {
  const schema = useSchema();
  const history = useHistory();
  const [snackbarContent, setSnackbarContent] = useState<string | null>(null);
  const {setToken} = useAuth();

  const navigateToHomePage = () => {
    history.push('/');
  };

  const handleLogin = async (values: {username: string; password: string}) => {
    const token = await login(values, setToken);
    if (token) {
      navigateToHomePage();
      return;
    }
    setSnackbarContent('Unable to log in. Make sure your credentials are valid.');
  };

  const closeSnackbar = (event: any, reason?: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarContent(null);
  };

  return (
    <>
      <Container maxWidth="xs" style={{minHeight: '100vh', paddingTop: '10vh'}}>
        <Formik initialValues={{username: '', password: ''}} validationSchema={schema} onSubmit={handleLogin}>
          {() => (
            <Paper elevation={3} style={{padding: '40px'}}>
              <Logo />
              <Typography variant="h3" style={{paddingTop: '20px', paddingBottom: '20px'}}>
                Sign in using your account
              </Typography>
              <Form autoComplete="off">
                <Field name="username" label="Username" />
                <Field name="password" label="Password" type="password" />
                <SubmitButton fullWidth style={{marginTop: '40px', fontWeight: 'bold'}}>
                  Sign in
                </SubmitButton>
              </Form>
            </Paper>
          )}
        </Formik>
      </Container>
      <Snackbar open={!!snackbarContent} autoHideDuration={3000} onClose={closeSnackbar} message={snackbarContent} />
    </>
  );
};

export default LoginPage;
