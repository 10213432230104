import React from 'react';
import {Typography} from '@material-ui/core';
import SecuredPage from '../SecuredPage';

const NotFoundPage = () => {
  return (
    <>
      <SecuredPage>
        <Typography variant="h1">Not Found!</Typography>
        <Typography variant="h2">The content you are requesting does not exist.</Typography>
      </SecuredPage>
    </>
  );
};

export default NotFoundPage;
