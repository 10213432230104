import React from 'react';
import {CssBaseline, ThemeProvider as MuiThemeProvider} from '@material-ui/core';
import {ProviderProps} from '../../../../../core';
import theme from '../../../theme';

const ThemeProvider = ({children}: ProviderProps) => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
