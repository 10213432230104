import {Box, Container} from '@material-ui/core';
import React from 'react';
import {ProviderProps} from '../../../../../core';
import useAuth from '../../../../common/hooks/useAuth';
import Header from '../Header';

const Layout = ({children}: ProviderProps) => {
  const {token} = useAuth();

  if (!token) {
    return <Container>{children as React.ReactElement}</Container>;
  }

  return (
    <>
      <Header />
      <Box py={5}>
        <Container>{children as React.ReactElement}</Container>
      </Box>
    </>
  );
};

export default Layout;
