import React from 'react';
import {StylesProvider as MuiStylesProvider} from '@material-ui/core/styles';
import {ProviderProps} from '../../../../../core';
import generateClassName from './generateClassName';

const StylesProvider = ({children}: ProviderProps) => {
  return <MuiStylesProvider generateClassName={generateClassName}>{children}</MuiStylesProvider>;
};

export default StylesProvider;
