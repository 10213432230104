import {addHandlers, addMiddlewareList, addProvider} from '../../core';
// import CsvParserProvider from './components/providers/CsvParserProvider';
// import MenuProvider from './components/providers/MenuProvider';
import * as middlewareList from './routing/middleware';
import {handlers, state} from './store';
import YupProvider from './components/providers/YupProvider';
import AuthProvider from './components/providers/AuthProvider';

addHandlers('common', handlers, state);
addMiddlewareList(middlewareList);
addProvider(YupProvider);
// addProvider(MenuProvider);
// addProvider(CsvParserProvider);
addProvider(AuthProvider);
