import {Handlers} from '../../typing';
import state from '../state';

export default <S>(name: string, handlers: Handlers<S>, defaultState: S) => {
  if (Object.prototype.hasOwnProperty.call(state.stores, name)) {
    throw new Error(`Handler [${name}] already defined.`);
  }

  state.stores[name] = {handlers, defaultState};
};
