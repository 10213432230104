import React from 'react';
import {Provider} from 'react-redux';
import {ProviderProps} from '../../typing';
import useMakeStore from './useMakeStore';

const Store = ({children}: ProviderProps) => {
  const store = useMakeStore();

  return <Provider store={store}>{children}</Provider>;
};

export default Store;
