import {TypographyOptions} from '@material-ui/core/styles/createTypography';
import {Theme} from '@material-ui/core/styles/createMuiTheme';

export default {
  fontFamily: ['Roboto', 'Arial', 'Helvetica', 'sans-serif'].join(','),
  h1: {
    fontSize: 48,
    fontWeight: 600,
    lineHeight: 1.4,
  },
  h2: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 1.4,
  },
  h3: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: 1.4,
  },
  h4: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  h6: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 1.75,
  },
} as TypographyOptions;

export const post = (theme: Theme) => {
  /* eslint-disable no-param-reassign */
  theme.typography.caption.color = theme.palette.text.light;
  theme.typography.h3.color = theme.palette.text.light;
  theme.typography.h4.color = theme.palette.text.light;
  /* eslint-enable no-param-reassign */
};
