import {RouteSchema} from '../../../core';

export interface State {
  pageTitle: string | null;
  backRoute: RouteSchema | null;
}

export default {
  pageTitle: null,
  backRoute: null,
} as State;
