import {NamedRouteProps} from '../../../../../core';
import addPrefix from '../../../utils/addPrefix';

export default (route: NamedRouteProps): NamedRouteProps => {
  if (typeof route.path !== 'string') {
    return route;
  }

  const paths: Array<string> = Array.isArray(route.path) ? route.path : [route.path];
  const prefixedPaths = paths.map<string>(addPrefix);

  return {
    ...route,
    path: prefixedPaths.length === 1 ? prefixedPaths[0] : prefixedPaths,
  };
};
