import {AppBar, Box, Link, Toolbar} from '@material-ui/core';
import React from 'react';
import {logout} from '../../../../common/actions/auth';
import useAuth from '../../../../common/hooks/useAuth';

const Header = () => {
  const {setToken} = useAuth();

  const handleLogout = () => {
    logout(setToken);
  };

  return (
    <AppBar position="static" color="secondary">
      <Toolbar>
        <Box px={3}>
          <Link href="/" variant="h6" underline="none" color="inherit">
            Home
          </Link>
        </Box>
        <Box px={3} style={{flexGrow: 1}}>
          <Link href="/about" variant="h6" underline="none" color="inherit">
            About
          </Link>
        </Box>
        <Box px={3}>
          <Link href="/login" variant="h6" underline="none" color="inherit" onClick={handleLogout}>
            Logout
          </Link>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
