import {Handlers} from '../../../../core';
import * as constants from '../constants';
import {State} from '../state';
import setBackRouteHandler from './setBackRouteHandler';
import setPageTitleHandler from './setPageTitleHandler';

export default {
  [constants.SET_BACK_ROUTE]: setBackRouteHandler,
  [constants.SET_PAGE_TITLE]: setPageTitleHandler,
} as Handlers<State>;
