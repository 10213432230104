import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Snackbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {AddCircle} from '@material-ui/icons';
import axios from 'axios';
import {Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import useAuth from '../../../common/hooks/useAuth';
import useYup from '../../../common/hooks/useYup';
import Field from '../../../ui/components/form/Field';
import Form from '../../../ui/components/form/Form';
import SubmitButton from '../../../ui/components/form/SubmitButton';
import SecuredPage from '../SecuredPage';

interface ObjectData {
  id: string;
  name: string;
  value: string;
}

const useSchema = () => {
  const Yup = useYup();

  return Yup.object().shape({
    name: Yup.string().required('Name must be specified'),
    value: Yup.string().required('Value must be specified'),
  });
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: 250,
    maxHeight: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
}));

const HomePage = () => {
  const schema = useSchema();
  const classes = useStyles();
  const {token} = useAuth();
  const [objects, setObjects] = useState<ObjectData[]>([]);
  const [selectedObjectIndex, setSelectedObjectIndex] = useState<number | null>(null); // -1 means new object
  const [snackbarContent, setSnackbarContent] = useState<string | null>(null);

  const axiosConfig = () => {
    return {headers: {Authorization: `Bearer ${token!.accessToken}`}};
  };

  const fetchObjects = async () => {
    try {
      const {data} = await axios.get('/api/objects', axiosConfig());
      setObjects(data.objects);
    } catch (err) {
      setObjects([]);
    }
  };

  const showCreateForm = () => {
    setSelectedObjectIndex(-1);
  };

  const selectObject = (index: number) => {
    return () => {
      setSelectedObjectIndex(index);
    };
  };

  const createObject = async (values: {name: string; value: string}) => {
    try {
      await axios.post('/api/objects', values, axiosConfig());
      setSnackbarContent('Successfully created object!');
    } catch {
      setSnackbarContent('Error while creating object...');
    } finally {
      setSelectedObjectIndex(null);
      fetchObjects();
    }
  };

  const updateObject = async (values: {id: string; name: string; value: string}) => {
    try {
      await axios.patch(`/api/objects/${values.id}`, {name: values.name, value: values.value}, axiosConfig());
      setSnackbarContent('Successfully updated object!');
    } catch {
      setSnackbarContent('Error while updating object...');
    } finally {
      setSelectedObjectIndex(null);
      fetchObjects();
    }
  };

  const deleteObject = async (values: {id: string}) => {
    try {
      await axios.delete(`/api/objects/${values.id}`, axiosConfig());
      setSnackbarContent('Successfully deleted object!');
    } catch {
      setSnackbarContent('Error while deleting object...');
    } finally {
      setSelectedObjectIndex(null);
      fetchObjects();
    }
  };

  const closeSnackbar = (event: any, reason?: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarContent(null);
  };

  const objectsList = () => {
    return (
      <>
        <Box py={2}>
          <Grid container direction="row" alignItems="center" justify="space-between">
            <Grid item>
              <Typography variant="h2">Current objects ({objects.length})</Typography>
            </Grid>
            <Grid item>
              <Tooltip title="Create" placement="right">
                <IconButton aria-label="create object" edge="end" size="small" onClick={showCreateForm}>
                  <AddCircle style={{color: 'rgb(253,64,129)', width: 40, height: 40}} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
        <div className={classes.root}>
          <List component="nav" aria-label="objects">
            {objects.map((obj, index) => {
              return (
                <ListItem button key={obj.name} onClick={selectObject(index)}>
                  <ListItemText primary={obj.name} />
                </ListItem>
              );
            })}
          </List>
        </div>
      </>
    );
  };

  const objectForm = () => {
    if (selectedObjectIndex === -1) {
      return (
        <Formik
          enableReinitialize
          initialValues={{name: `New name ${objects.length + 1}`, value: 'New value'}}
          validationSchema={schema}
          onSubmit={createObject}>
          {() => (
            <Form>
              <Field name="name" label="Name" />
              <Field name="value" label="Value" />
              <Box py={1}>
                <SubmitButton>Create</SubmitButton>
              </Box>
            </Form>
          )}
        </Formik>
      );
    }

    if (selectedObjectIndex !== null && selectedObjectIndex >= 0 && selectedObjectIndex < objects.length) {
      return (
        <Formik
          enableReinitialize
          initialValues={{
            id: objects[selectedObjectIndex].id,
            name: objects[selectedObjectIndex].name,
            value: objects[selectedObjectIndex].value,
          }}
          validationSchema={schema}
          onSubmit={updateObject}>
          {(props) => (
            <Form>
              <Field name="id" label="ID" disabled="true" />
              <Field name="name" label="Name" />
              <Field name="value" label="Value" />
              <Box py={1}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <SubmitButton>Update</SubmitButton>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="primary" onClick={() => deleteObject(props.values)}>
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      );
    }

    return <></>;
  };

  useEffect(() => {
    fetchObjects();
  }, []);

  return (
    <>
      <SecuredPage>
        <Grid container alignItems="flex-end" spacing={4}>
          <Grid item xs={6}>
            {objectsList()}
          </Grid>
          <Grid item xs={6}>
            {objectForm()}
          </Grid>
        </Grid>
        <Snackbar open={!!snackbarContent} autoHideDuration={3000} onClose={closeSnackbar} message={snackbarContent} />
      </SecuredPage>
    </>
  );
};

export default HomePage;
