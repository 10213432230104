import state from '../state';
import {Middleware} from '../../typing';

export default (name: string, middleware: Middleware) => {
  if (Object.prototype.hasOwnProperty.call(state.routing.middleware, name)) {
    throw new Error(`Middleware [${name}] already defined.`);
  }

  state.routing.middleware[name] = middleware;
};
