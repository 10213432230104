export default new Promise<void>((resolve) => {
  if (typeof window === 'undefined') {
    resolve();

    return;
  }

  if (document.readyState === 'loading') {
    const onLoaded = () => {
      resolve();
      document.removeEventListener('DOMContentLoaded', onLoaded);
      window.removeEventListener('load', onLoaded);
    };

    document.addEventListener('DOMContentLoaded', onLoaded);
    window.addEventListener('load', onLoaded);
  } else {
    resolve();
  }
});
