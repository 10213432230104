import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {ProviderProps} from '../../../../core';
import {logout} from '../../../common/actions/auth';
import {Token} from '../../../common/contexts/AuthContext';
import useAuth from '../../../common/hooks/useAuth';

const isAboutToExpire = (token: Token) => {
  return token.expiresAt < Date.now() / 1000 - 5 * 60;
};

const SecuredPage = ({children}: ProviderProps) => {
  const {token, setToken} = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (token === null || isAboutToExpire(token)) {
      logout(setToken);
      history.push('/login');
    }
  });

  return <>{children}</>;
};

export default SecuredPage;
