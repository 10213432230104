import AboutPage from '../../pages/AboutPage';
import HomePage from '../../pages/HomePage/HomePage';
import LoginPage from '../../pages/LoginPage';
import NotFoundPage from '../../pages/NotFoundPage/NotFoundPage';

export default [
  {name: 'app.home', path: '/', component: HomePage, exact: true},
  {name: 'app.about', path: '/about', component: AboutPage, exact: true},
  {name: 'app.login', path: '/login', component: LoginPage, exact: true},
  {name: 'app.notFound', path: '/*', component: NotFoundPage},
];
