import {PaletteOptions} from '@material-ui/core/styles/createPalette';
import {Theme} from '@material-ui/core/styles/createMuiTheme';

export default {
  primary: {
    main: '#51bceb',
    contrastText: '#fff',
  },
  secondary: {
    main: '#002D42',
  },
  tertiary: {
    main: '#212121',
  },
  common: {
    black: 'rgb(33,33,33)',
  },
  background: {
    default: '#f5f5f5',
  },
  text: {
    light: '#707070',
    dark: '#212121',
  },
} as PaletteOptions;

export const post = (theme: Theme) => {
  /* eslint-disable no-param-reassign */
  theme.palette.tertiary = theme.palette.augmentColor(theme.palette.tertiary);
  /* eslint-enable no-param-reassign */
};
