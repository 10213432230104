import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import React from 'react';
import {ProviderProps} from '../../../../../core';

const DatePickerProvider = ({children}: ProviderProps) => {
  return <MuiPickersUtilsProvider utils={DateFnsUtils}>{children}</MuiPickersUtilsProvider>;
};

export default DatePickerProvider;
