import {useMemo} from 'react';
import {getProviders} from '../../api';
import {Provider, ProviderWithWeight} from '../../typing';
import byWeight from '../../utils/byWeight';

export default (): Array<Provider> => {
  const providers = getProviders();

  return useMemo(
    () => [...providers].sort(byWeight).map(({provider}: ProviderWithWeight) => provider),
    [providers.length],
  );
};
