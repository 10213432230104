import {Fragment} from 'react';
import state from '../state';
import {Layout} from '../../typing';

export default (layout: Layout) => {
  if (state.layout !== Fragment) {
    throw new Error('Layout already defined.');
  }

  state.layout = layout;
};
