import {addProvider, DEPENDENCY_WEIGHT, DEPENDENT_WEIGHT} from '../../core';
import DatePickerProvider from './components/providers/DatePickerProvider';
import SnackbarProvider from './components/providers/SnackbarProvider';
import StylesProvider from './components/providers/StylesProvider';
import ThemeProvider from './components/providers/ThemeProvider';

addProvider(StylesProvider, DEPENDENCY_WEIGHT);
addProvider(ThemeProvider);
addProvider(DatePickerProvider);
addProvider(SnackbarProvider, DEPENDENT_WEIGHT);
