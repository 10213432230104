import React from 'react';
import {Provider, ProviderProps} from '../../typing';
import useProviders from './useProviders';

const Providers = ({children}: ProviderProps) => {
  const providers = useProviders();

  return (
    <>
      {providers.reduceRight((content, Component: Provider) => {
        return <Component>{content}</Component>;
      }, children)}
    </>
  );
};

export default Providers;
