import React, {useState} from 'react';
import {ProviderProps} from '../../../../../core';
import AuthContext, {Token} from '../../../contexts/AuthContext';

const initialToken = localStorage.getItem('currentAuth')
  ? JSON.parse(localStorage.getItem('currentAuth') as string)
  : null;

const AuthProvider = ({children}: ProviderProps) => {
  const [token, setToken] = useState<Token | null>(initialToken);
  return <AuthContext.Provider value={{token, setToken}}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
